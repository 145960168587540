import { Trans } from '@lingui/react';
import styled from 'styled-components';
import { LeftBox } from '@pages/[lang]/register/account';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import { Box, Flex } from '@components/layout/Grid';
import Button from '@components/buttons';
import { H2 } from '@components/typography/Headings';

const LeftContainer = styled(RegisterFormTemplate.LeftContainer)`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  background-position: right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    height: 100vh;
    background-image: linear-gradient(
        to right,
        #fbfeff 70%,
        rgba(239, 250, 255, 0.8) 100%
      ),
      url('/static/registration-bg-new.jpg');
  }
`;

const Content = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: block;
  }
`;

export const Title = styled(H2)`
  max-width: 80%;
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    margin-top: 40px;
  }
`;

const LogoLink = styled.a`
  display: inline-block;
  text-decoration: none;
`;

const Wellness = styled(Box)`
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 2px 24px 0 rgba(86, 185, 229, 0.08);

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const WellnessText = styled.p`
  font-size: 14px;
  font-style: italic;
  line-height: 22px;
  padding-right: 20px;
  margin: 0 0 14px;

  a {
    color: #129edc;
    font-weight: 600;
    cursor: pointer;
  }
`;

const Logo = styled.img`
  height: 20px;
  object-fit: contain;
`;

const SmallLogo = styled(Logo)`
  height: 14px;
`;

const LoginTemplateLeftContent = () => {
  return (
    <LeftContainer>
      <LeftBox>
        <LogoLink href="/">
          <RegisterFormTemplate.Logo />
        </LogoLink>
        <Content>
          <Title>
            <Trans id="login_page.landing.headline" />
          </Title>
          <RegisterFormTemplate.Paragraph>
            <Trans id="login_page.landing.subtitle" />
          </RegisterFormTemplate.Paragraph>
          <Wellness mt={30} px={20} py={20}>
            <WellnessText>
              <Trans
                id="registration.wellness.learn_more"
                components={[
                  <Button
                    key="0"
                    appearance="link"
                    href="/se/friskvardsbidrag"
                  ></Button>,
                ]}
              />
            </WellnessText>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Logo src="/static/icons/benify.png" />
              <Logo src="/static/icons/epassi.png" />
              <SmallLogo src="/static/icons/wellnet.png" />
              <Logo src="/static/icons/soderberg.png" />
            </Flex>
          </Wellness>
        </Content>
      </LeftBox>
    </LeftContainer>
  );
};

export default LoginTemplateLeftContent;
