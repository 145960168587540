import React from 'react';
import Styled from 'styled-components';
import { useRouter } from 'next/router';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import MetaTags from '@components/MetaTags';
import redirect from '@lib/redirect';
import LoginForm from '@ecosystems/login/LoginForm';
import LoginTemplateLeftContent from '@ecosystems/login/LoginTemplateLeftContent';
import { Flex } from '@components/layout/Grid';
import { getCodeFromReturnUrl } from '@lib/utils';
import CodeFoundBanner from '@organisms/stripeBanners/CodeFoundBanner';
import { Trans } from '@lingui/react';

// IMPORTANT
// TO KEEP ALL THE REGISTRATION PAGES LAYOUT/SPACING CONSISTENT
// WE WILL USE THE IMPORTED ELEMENTS FROM MAIN REGISTRATION
export const Wrapper = RegisterFormTemplate.Wrapper;

export const LeftBox = Styled.div`
  max-width: 500px;
  padding: 30px 20px;
  box-sizing: content-box;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    padding: 30px;
    margin-right: 10%;
    
    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right,rgba(223,245,255,0.95) 0%,rgba(223,245,255,0.95) 60%,rgba(223,245,255,0.1) 100%);
      z-index: -1;
    }
  }
`;

export const FormTitle = Styled.p`
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 10px;
`;

export const RightBox = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  padding: 20px 20px 30px;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    padding: 30px 30px 60px;
    max-width: 600px;
  }
`;

export const RightContainer = Styled(RegisterFormTemplate.RightContainer)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutrals.white};
  /* needed on safari to keep this above left container during scroll on mobile */
  z-index: 10;
  /* wehn confetti is present it creates a horizontal scroll */
  overflow-x: hidden;
`;

export const Errors = Styled.ul`
  max-width: 500px;
  text-align: center;
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
`;

export const Error = Styled.li`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary['red-1']};
  margin: 0;
`;

export const Footer = Styled(RegisterFormTemplate.Footer)`
  width: 100%;
`;

/*
 the page supports two types of redirect params:
  - to: will be carried to the subscription page, after the payment we do the redirect
  - redirect_to|redirectTo: user will be redirected after the registration. (redirectTo is supported because of legacy usage)

  * redirect_to|redirectTo takes precedence over 'to'
*/

const Register = () => {
  const router = useRouter();
  const lang = router.query.lang as string;
  const code = getCodeFromReturnUrl(router.query?.to);

  const loggedIn = () => {
    const { paymentType, redirectTo, redirect_to, to } = router.query;
    const redirectURL = (to || redirectTo || redirect_to) as string;
    if (redirectURL) {
      window.location.href = redirectURL;
    } else if (paymentType) {
      window.location.href = `/${lang}/subscription/select?paymentType=${paymentType}`;
    } else {
      window.location.href = `/${lang}/me/dashboard`;
    }
  };

  return (
    <>
      <MetaTags
        title="Yoga och träning online för hållbar hälsa"
        description="Yoga & träna på nätet med några av Sveriges främsta instruktörer. Yogavideos online vart du vill, när du vill."
      />
      <Wrapper>
        <LoginTemplateLeftContent />
        <RightContainer>
          <CodeFoundBanner show={!!code}>
            <Trans id="login_page.discount_code_found" values={{ code }} />
          </CodeFoundBanner>
          <Flex flex={1} alignItems="center" px={[20, 20, 0]}>
            <LoginForm
              callBack={() => {
                loggedIn();
              }}
            />
          </Flex>
          <Footer />
        </RightContainer>
      </Wrapper>
    </>
  );
};

export const getServerSideProps = ctx => {
  const { currentUser } = ctx.req;
  const { lang, redirect_to, redirectTo, to } = ctx.query;

  if (currentUser?.id) {
    if (redirectTo || redirect_to || to) {
      const url = redirectTo || redirect_to || to;
      redirect(ctx, url);
    } else {
      redirect(ctx, `/${lang}/me/dashboard`);
    }
  }

  return {
    props: {},
  };
};

Register.getPageConfig = () => {
  return {
    layout: 'none',
  };
};

export default Register;
